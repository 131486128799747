import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';
import { NgModule, LOCALE_ID } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { HttpService } from "./services/Http/http.service"
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import { DataTablesModule } from 'angular-datatables';
import { AlertModule } from 'ngx-alerts';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { NgxLoadingModule } from 'ngx-loading';
import {NgxMaskModule} from 'ngx-mask'
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { IgxDatePickerModule } from 'igniteui-angular';
import { CalendarModule } from 'angular-calendar';
import { ContentLoaderModule } from '@netbasal/content-loader';
import { AgmCoreModule } from '@agm/core';
import { FormatHoursPipe } from "@pipes/format-hours.pipe"
import { NgSelectModule } from '@ng-select/ng-select';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
//import { ToastModule } from 'ng-mdb-pro/pro/alerts';
import { AmazingTimePickerModule } from 'amazing-time-picker';
import { AppComponent } from './app.component';
import {AppRoutingModule } from "./app-routing.module"
import { DashboardComponent } from './dashboard/dashboard.component';
import { InfoComponent } from './dashboard/info/info.component';
import { UsersComponent } from './users/users.component';
import { LoginComponent } from './login/login.component';
import { routes } from "./routes/routes";
import { CompaniesComponent } from './companies/companies.component';
import { UsersDetailComponentComponent } from './users/users-detail-component/users-detail-component.component';
import { SharedService } from "./services/eventsEmmiter/shared.service";
import { RolesService } from "./services/globals/roles.service";
import { ScheduleComponent } from './schedule/schedule.component';
import { SchedulesDetailComponent } from './schedule/schedules-detail/schedules-detail.component';
import { UserCalendarComponent } from './globals/user-calendar/user-calendar.component';
import { HolidaysComponent } from './config/holidays/holidays.component';
import { CardsComponent } from './users/cards/cards.component';
import { CardsDetailComponent } from './users/cards/cards-detail/cards-detail.component';
import { RequestsComponent } from './users/requests/requests.component';
import { DatePipe } from './globals/pipes/date.pipe';
import { MarksComponent } from './users/marks/marks.component';
import { ChecksComponent } from './users/checks_new/checks.component'
import { MarksPhoneComponent } from './users/marks/marks-phone/marks-phone.component';
import {Angular2PromiseButtonModule} from 'angular2-promise-buttons/dist';
import { ContentLoaderChecksComponent } from './globals/content-loader-checks/content-loader-checks.component';
import { HoursPipe } from './globals/pipes/hours.pipe';
import { FiltersCompaniesComponent } from './globals/filters/companies/companies.component'
import { ButtonLoaderComponent } from './html/button-loader/button-loader.component';
import { ParseNumberPipe } from './globals/pipes/parse-number.pipe';
import { ModalComponent } from './html/modals/modal/modal.component';
import { ModalConfirmComponent } from './html/modals/modal-confirm/modal-confirm.component';
import { DomService } from "@html/modals/modal-confirm/modal-service";
import { WizardComponent } from './html/dsteps/wizard.component'
import { WizardStepComponent } from './html/dsteps/wizard-step.component';
import { DelaysComponent } from './reports/users/delays/delays.component';
import { DistributionComponent } from './reports/users/distribution/distribution.component';
import { SpecialComponent } from './reports/users/special_days/special.component';
import { AbsencesComponent } from './reports/users/absences/absences.component';
import { ExtraHoursComponent } from './reports/users/extra-hours/extra-hours.component';
import { GaugesComponent } from './html/gauges/gauges.component'
import { GaugeModule } from 'angular-gauge';
import { ExtraApprovesComponent } from './reports/users/extra-approves/extra-approves.component';
import { VacationsComponent } from './reports/users/vacations/vacations.component';
import { IncapacityComponent } from './reports/users/incapacity/incapacity.component';
import localeEs from '@angular/common/locales/es';
import { MaintenancePageComponent } from './globals/maintenance-page/maintenance-page.component';
import { PasswordComponent } from './users/password/password.component';
import { DayPipePipe } from './globals/pipes/day-pipe.pipe';
import { ScheduleWarningComponent } from './html/schedule-warning/schedule-warning.component';
import { HierarchiesPipe } from './globals/pipes/hierarchies.pipe';
import { ScheduleTableComponent } from './schedule/schedule-table/schedule-table.component';
import { SpecialScheduleComponent } from './html/special-schedule/special-schedule.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { AuthComponent } from './globals/auth/auth.component';
import { UsersEditScheduleComponent } from './html/users-edit-schedule/users-edit-schedule.component';
import { ManualTimeDirective } from './globals/directives/manual-time/manual-time.directive';
import { SummaryComponent } from './reports/users/summary/summary.component';
import { ConceptsComponent } from './reports/users/concepts/concepts.component';
import { ConceptsSimpleComponent } from './reports/users/concepts-simple/concepts-simple.component';
import { PeriodsComponent } from './reports/users/periods/periods.component';
import { IslandsComponent } from './reports/users/islands/islands.component';
import { ConceptsPaymentsComponent } from './users/concepts/concepts-payments.component';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    ConceptsPaymentsComponent,
    AppComponent,
    IslandsComponent,
    DashboardComponent,
    InfoComponent,
    UsersComponent,
    LoginComponent,
    CompaniesComponent,
    UsersDetailComponentComponent,
    ScheduleComponent,
    SchedulesDetailComponent,
    UserCalendarComponent,
    HolidaysComponent,
    CardsComponent,
    CardsDetailComponent,
    RequestsComponent,
    DatePipe,
    MarksComponent,
    MarksPhoneComponent,
    ContentLoaderChecksComponent,
    HoursPipe,
    FormatHoursPipe,
    ButtonLoaderComponent,
    ParseNumberPipe,
    ModalComponent,
    ModalConfirmComponent,
    WizardComponent,
    WizardStepComponent,
    DelaysComponent,
    AbsencesComponent,
    ExtraHoursComponent,
    GaugesComponent,
    ExtraApprovesComponent,
    VacationsComponent,
    IncapacityComponent,
    DistributionComponent,
    FiltersCompaniesComponent,
    MaintenancePageComponent,
    ChecksComponent,
    SpecialComponent,
    PasswordComponent,
    DayPipePipe,
    ScheduleWarningComponent,
    HierarchiesPipe,
    ScheduleTableComponent,
    SpecialScheduleComponent,
    AuthComponent,
    UsersEditScheduleComponent,
    ManualTimeDirective,
    SummaryComponent,
    ConceptsComponent,
    ConceptsSimpleComponent,
    PeriodsComponent

  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true}),
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    AlertModule.forRoot({maxMessages: 5, timeout: 4000}),
    NgxSmartModalModule.forRoot(),
    NgbModule.forRoot(),
    DataTablesModule,
    AmazingTimePickerModule,
    IgxDatePickerModule,
    CalendarModule.forRoot(),
    ContentLoaderModule,
    AgmCoreModule.forRoot({
     apiKey: 'AIzaSyB07SSMEbwCR10kA4xdqJo5COUCs4Y3_B8'
   }),
   ScrollToModule.forRoot(),
   Angular2PromiseButtonModule.forRoot(),
   NgSelectModule,
   GaugeModule.forRoot(),
   NgxLoadingModule.forRoot({}),
   NgxMaskModule.forRoot(),
   ColorPickerModule
  ],
  providers: [
    SharedService,
    RolesService,
    HoursPipe,
    FormatHoursPipe,DomService,
    {provide: LOCALE_ID, useValue: 'es'}
  ],
  bootstrap: [AppComponent],
  entryComponents: [ ModalConfirmComponent ]
})
export class AppModule { }
