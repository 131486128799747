import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output} from '@angular/core';
import { ComponentRef, EventEmitter, ViewChild } from '@angular/core';
import { AmazingTimePickerService } from 'amazing-time-picker';
import { FormatHoursPipe } from "@pipes/format-hours.pipe";
import { Router,  ActivatedRoute } from "@angular/router";
import { HttpService } from "@services/Http/http.service"
import { AlertService } from 'ngx-alerts'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GlobalsService } from '@services/globals/globals.service';
import * as moment from "moment";
import { IgxDatePickerModule } from "igniteui-angular";
import { IgxDatePickerComponent, DateRangeType } from "igniteui-angular";

@Component({
  selector: 'app-concepts-payments',
  templateUrl: './concepts-payments.component.html',
  styleUrls: ['./concepts-payments.component.scss']
})
export class ConceptsPaymentsComponent implements OnInit {

  	constructor(  		
		public httpService: HttpService,	   
	   	private formatHoursPipe: FormatHoursPipe,
	    private alertService: AlertService,
	    private router: Router,	
	    public gs: GlobalsService,
	    private atp: AmazingTimePickerService
  	) { 
  	}

  	// datatables
  	@ViewChild(DataTableDirective) dtElement: DataTableDirective;
  	@ViewChild("uidt") dpicker: IgxDatePickerComponent;
  	dtTrigger: Subject<any> = new Subject();  	
  	dtOptions: any = {	 
	    language : {
	      	url : "https://cdn.datatables.net/plug-ins/1.10.19/i18n/Spanish.json",
	      	emptyTable : "No existen usuarios"
	    },
	    pageLength : 25     
	};	
	users = [];
	isLoadingTab = false;
	options: any = {
        text:"Guardar",
        disabled: false,
        type:"btn btn-primary",
        icon:"fa fa-spinner fa-spin",
        isLoading:false
    };
    options_btn_search : any = {
        text:"Buscar",
        disabled: false,
        type:"btn btn-default",
        icon:"fa fa-spinner fa-spin",
        isLoading:false
    };
	grploader : any = JSON.parse(localStorage.getItem('grupo'));  
	ng_concept : any = {};
	datatables = [];
	ng_list_concepts = [];
	ng_start_date = new Date();
	ng_end_date = new Date();

	ngOnInit() {	
		this.reset()
       	this.httpService.getHttpAuth("/conceptos-pagos/conceptos").subscribe(x => {
       	    this.ng_list_concepts = x.data
       	     this.search(); 
       	});
	}
	
	reset() {	
		this.ng_concept = {
	        id_concepto : 0,
	        users : [],
	        fecha : "",
	        fecha_ctr : new Date(),
	        monto : 0,
	        descripcion : ""
	    };
	}
	
	search() {   	
        let uri = '?start='+moment(this.ng_start_date).format('YYYY-M-D')+'&end='+moment(this.ng_end_date).format('YYYY-M-D');
        this.isLoadingTab = true;
        this.httpService.getHttpAuth("/conceptos-pagos/"+uri).subscribe(x => {
            this.datatables = [];
        	this.datatables = x.data;
        	this.isLoadingTab = false;
        	this.rerender();
        });
   	}
   	
   	save(){
  		if(this.ng_concept.users.length == 0) {
  			this.alertService.info("Seleccione un usuario");
  			return;
  		}
  		if(this.ng_concept.id_concepto == 0) {  		
  			this.alertService.info("Seleccione un concepto"); 
  			return;
  		} 
  		this.ng_concept.fecha = moment(this.ng_concept.fecha_ctr).format('YYYY-M-D');
  	    this.options.isLoading = true;
  	    this.options.disabled = true;
    	this.httpService.postHttpAuth("/conceptos-pagos", this.ng_concept).subscribe(x => {
    	    (<any>$("#modalConcepts")).modal("hide");
    		this.options.isLoading = false;
  		    this.options.disabled = false;
  			this.alertService.success("Pago guardado existosamente");
  			this.search();
    	}, err =>  {
    		this.options.isLoading = false;
  		    this.options.disabled = false;
    	});
  	}
   	
   	changeDataUser(){
    
    }

    disableDates() : void {     	    	
    	/*this.dpicker.disabledDates = [];   
		let date = moment(this.ng_start_date).toDate(); 	
		let disableFrom = moment(date).subtract(100, 'years').toDate();    	
      	var dateMax = date;
      	var dateMin = disableFrom;      	
      	this.dpicker.disabledDates = [
        	{type: DateRangeType.Between,dateRange:[dateMin, dateMax]}
        ];*/        	
    }  

	ngAfterViewInit(): void {
    	this.dtTrigger.next();
    	this.disableDates(); 
  	}

  	ngOnDestroy() {
  		this.dtTrigger.unsubscribe();
  	}

  	rerender(): void {
    	this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      		// Destroy the table first
      		dtInstance.destroy();
      		// Call the dtTrigger to rerender again
      		this.dtTrigger.next();
    	});
  	}

	open_modal() {
	    this.httpService.postHttpAuth("/users/bygroup",{"id_grupo":this.grploader.grupos.empresa.id,"jerarquia":1}).subscribe(data => {
            for (let i = 0; i < data.users.length; i++) {
                data.users[i].nombre = data.users[i].nombre + " " + data.users[i].apellido;
            }
            this.users = data.users;
            (<any>$("#modalConcepts")).modal("show");
	    });
	}

	close_modal(){
	    (<any>$("#modalConcepts")).modal("hide");
	}

  	delete(id){
  	    this.httpService.deleteHttpAuth("/conceptos-pagos/" + id , {}).subscribe(data => {
          	this.alertService.success("Concepto eliminado exitosamente");
  		    this.search();
  		});
  	}
}