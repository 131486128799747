import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './../dashboard/dashboard.component';
import { InfoComponent } from './../dashboard/info/info.component';
import { UsersComponent } from './../users/users.component';
import { LoginComponent } from './../login/login.component';
import { CompaniesComponent } from  "./../companies/companies.component";
import { UsersDetailComponentComponent } from "./../users/users-detail-component/users-detail-component.component"
import { ScheduleComponent } from './../schedule/schedule.component'
import { SchedulesDetailComponent } from './../schedule/schedules-detail/schedules-detail.component'
import { ConceptsPaymentsComponent } from './../users/concepts/concepts-payments.component';
import { HolidaysComponent } from './../config/holidays/holidays.component'
import { CardsComponent } from './../users/cards/cards.component'
import { CardsDetailComponent } from './../users/cards/cards-detail/cards-detail.component'
import { RequestsComponent } from './../users/requests/requests.component'
import { MarksComponent } from './../users/marks/marks.component'
import { ChecksComponent } from './../users/checks_new/checks.component'
import { MarksPhoneComponent } from './../users/marks/marks-phone/marks-phone.component';
import { DelaysComponent } from './../reports/users/delays/delays.component';
import { AbsencesComponent } from './../reports/users/absences/absences.component'
import { ExtraHoursComponent } from './../reports/users/extra-hours/extra-hours.component'
import { ExtraApprovesComponent } from './../reports/users/extra-approves/extra-approves.component';
import { PeriodsComponent } from './../reports/users/periods/periods.component';
import { VacationsComponent } from './../reports/users/vacations/vacations.component';
import { IncapacityComponent } from './../reports/users/incapacity/incapacity.component';
import { DistributionComponent } from './../reports/users/distribution/distribution.component';
import { SpecialComponent } from './../reports/users/special_days/special.component';
import { SummaryComponent } from './../reports/users/summary/summary.component';
import { MaintenancePageComponent } from './../globals/maintenance-page/maintenance-page.component';
import { PasswordComponent } from './../users/password/password.component';
import { ScheduleTableComponent } from './../schedule/schedule-table/schedule-table.component';
import { AuthComponent } from './../globals/auth/auth.component';
import { ConceptsComponent } from './../reports/users/concepts/concepts.component';
import { IslandsComponent } from './../reports/users/islands/islands.component';
import { ConceptsSimpleComponent } from './../reports/users/concepts-simple/concepts-simple.component';

const routes: Routes = [

  { path: 'login', component: LoginComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'password', component: PasswordComponent },
  { path: 'password/:token/:email', component: PasswordComponent },
  {
    path: 'dashboard',
    component: DashboardComponent, children: [
        {
            path: 'info',
            component: InfoComponent,
            outlet:"section"
        },
        {
            path: 'companies',
            component: CompaniesComponent,
            outlet:"section"
        },
        {
            path: 'users',
            component: UsersComponent,
            outlet:"section"
        },
        {
            path: 'users/:id',
            component: UsersDetailComponentComponent,
            outlet:"section"
        },
        {
            path: 'schedules',
            component: ScheduleComponent,
            outlet:"section"
        },
        {
            path: 'deparment-schedules',
            component: SchedulesDetailComponent,
            outlet:"section"
        },
        {
            path: 'schedule-table',
            component: ScheduleTableComponent,
            outlet:"section"
        },
        {
            path: 'holidays',
            component: HolidaysComponent,
            outlet:"section"
        },
        {
            path: 'cards',
            component: CardsComponent,
            outlet:"section"
        },
        {
            path: 'cards/:id',
            component: CardsDetailComponent,
            outlet:"section"
        },
        {
            path: 'cards/:id/:id_corte',
            component: CardsDetailComponent,
            outlet:"section"
        },
        {
            path: 'requests',
            component: RequestsComponent,
            outlet:"section"
        },
        {
            path: 'concepts-payments',
            component: ConceptsPaymentsComponent,
            outlet:"section"
        },
        {
            path: 'checks/details',
            component: MarksComponent,
            outlet:"section"
        },
        {
            path: 'checks',
            component: MarksComponent,
            outlet:"section"
        },
        {
          path:"checks-phone",
          component: MarksPhoneComponent,
          outlet: "section"
        },
        {
          path:"reports/delays",
          component: DelaysComponent,
          outlet: "section"
        },
        {
          path:"reports/islands",
          component: IslandsComponent,
          outlet: "section"
        },
        {
          path:"reports/absences",
          component: AbsencesComponent,
          outlet: "section"
        },
        {
          path:"reports/extras",
          component: ExtraHoursComponent,
          outlet: "section"
        },
        {
          path:"reports/periods",
          component: PeriodsComponent,
          outlet: "section"
        },

        {
          path:"reports/extras-approved",
          component: ExtraApprovesComponent,
          outlet: "section"        
        },
        {
          path:"reports/vacations",
          component: VacationsComponent,
          outlet: "section"        
        },
        {
          path:"reports/incapacity",
          component: IncapacityComponent,
          outlet: "section"        
        },
        {
          path:"reports/distribution",
          component: DistributionComponent,
          outlet: "section"        
        },
        {
          path:"reports/special",
          component:SpecialComponent,
          outlet:"section"
        },
        {
          path:"reports/summary",
          component:SummaryComponent,
          outlet:"section"
        },
        {
          path:"reports/concepts",
          component:ConceptsComponent,
          outlet:"section"
        },
        {
          path:"reports/concepts-simple",
          component:ConceptsSimpleComponent,
          outlet:"section"
        }
    ]
  },
  { path: '', component: LoginComponent },
  {
    path:"maintance",
    component: MaintenancePageComponent
  }
];

export { routes }
